<template>
  <div>
    <HeaderPanel
      title="BRANCH MANAGEMENT"
      :filter="filter"
      placeholder="Search Plant ID, Branch Name"
      @sidebar="sidebarFilter"
      @search="getBranchList"
      :hasDropdown="false"
      routerPath="/branch-management/detail/0"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <Table
        :filter="filter"
        :pageOptions="pageOptions"
        :fields="fields"
        :items="items"
        :isBusy="isBusy"
        :rows="rows"
        :showingTo="showingTo"
        @filterPage="filterPage"
        @handleCheckAll="handleCheckAll"
      />
    </div>
    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      placeholder="Search Plant ID, Branch Name"
      @searchAll="getBranchList"
      @clearFilter="clearFilter"
    >
      <template v-slot:filter-option>
        <b-form-group>
          <template #label>
            <b>Branch Group</b><br />
            <b-form-checkbox
              v-model="branchSelectAll"
              aria-describedby="status"
              aria-controls="status"
              @change="branchToggleAll"
            >
              Select All
            </b-form-checkbox>
          </template>
          <template v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              id="status"
              v-model="filter.branchGroup"
              :options="branchSelectOption"
              :aria-describedby="ariaDescribedby"
              name="status"
              class="ml-4"
              value-field="id"
              text-field="name"
              aria-label="Individual status"
              stacked
            ></b-form-checkbox-group>
          </template>
        </b-form-group>
        <b-form-group>
          <template #label>
            <b>Type</b><br />
            <b-form-checkbox
              v-model="typeSelectAll"
              aria-describedby="type"
              aria-controls="type"
              @change="typeToggleAll"
            >
              Select All
            </b-form-checkbox>
          </template>
          <template v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              id="type"
              v-model="filter.branchType"
              :options="typeSelectOption"
              :aria-describedby="ariaDescribedby"
              name="type"
              class="ml-4"
              value-field="id"
              text-field="name"
              aria-label="Individual type"
              stacked
            ></b-form-checkbox-group>
          </template>
        </b-form-group>
      </template>
    </SideBarFilter>
  </div>
</template>
<script>
import HeaderPanel from "@/components/HeaderPanel";
import Table from "./components/Table";
import SideBarFilter from "@/components/SideBarFilter";

export default {
  components: {
    HeaderPanel,
    SideBarFilter,
    Table,
  },
  data() {
    return {
      branchSelectAll: false,
      branchSelected: [],
      branchSelectOption: [],
      typeSelectAll: false,
      typeSelected: [],

      typeSelectOption: [],
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        search: "",
        page: 1,
        take: 10,
        status: [],
        branchType: [],
        branchGroup: [],
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      fields: [
        {
          key: "plant_id",
          label: "Plant ID",
          thClass: "upper",
        },
        {
          key: "name",
          label: "Branch Name",
        },
        {
          key: "branch_group",
          label: "Branch Group",
        },
        {
          key: "total_price",
          label: "Total Price",
        },
        {
          key: "available",
          label: "Available",
        },
        {
          key: "branch_type",
          label: "Type",
        },
        {
          key: "active",
          label: "Status",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
      items: [],
    };
  },
  watch: {
    "filter.branchGroup"(newValue) {
      if (newValue.length === 0) {
        this.branchSelectAll = false;
      } else if (newValue.length === this.branchSelectOption.length) {
        this.branchSelectAll = true;
      } else {
        this.branchSelectAll = false;
      }
    },
    "filter.branchType"(newValue) {
      if (newValue.length === 0) {
        this.typeSelectAll = false;
      } else if (newValue.length === this.typeSelectOption.length) {
        this.typeSelectAll = true;
      } else {
        this.typeSelectAll = false;
      }
    },
  },
  created() {
    this.getBranchList();
    this.getOptions();
  },
  methods: {
    handleCheckAll(val) {
      this.items.map((el) => el.isChecked == val);
      for (const items of this.items) {
        items.isChecked = val;
      }

      this.items = [...this.items];
    },
    async getOptions() {
      const type = await this.axios(`/branch/type`);
      const group = await this.axios(`/branch/groupTemplate`);
      this.branchSelectOption = group.data.detail;
      this.typeSelectOption = type.data.detail;
    },
    getBranchList() {
      this.isBusy = true;

      this.axios.post(`/branch/list`, this.filter).then((res) => {
        if (res.data.result) {
          this.rows = res.data.detail.total;
          this.items = res.data.detail.data;
          this.isBusy = false;
        }
      });
    },

    branchToggleAll(checked) {
      this.filter.branchGroup = checked
        ? this.branchSelectOption.map((el) => el.id).slice()
        : [];
    },
    typeToggleAll(checked) {
      this.filter.branchType = checked
        ? this.typeSelectOption.map((el) => el.id).slice()
        : [];
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    clearFilter() {
      this.filter = {
        search: "",
        page: 1,
        take: 10,
        status: [],
        branchType: [],
        branchGroup: [],
      };
    },
    filterPage(filter) {
      this.filter = filter;
      this.getBranchList();
    },
  },
};
</script>
